
























import { Component, Vue } from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class QuestionDetail extends Vue {
  public question = {};
  public settings: Settings;
  public id: number;

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public loadQuestion(): void {
    this.axios.get(this.settings.settings.host + '/api/questions/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.question = response.data;
    });
  }

  created(): void {
    console.log('question loaded');
    this.loadQuestion();
  }
}
